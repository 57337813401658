import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const managerUpgrades = () => {
  return (
    <Layout>
      <SEO
        title="Manager Upgrades"
        description="Learn how Team Spirit, Tactical Training, Familiarity and Management Skills work in PES 2021 myClub."
      />
      <div className="container">
        <h1>Manager Upgrades</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Manager Upgrades</li>
        </ul>
        <p>
          After signing a top manager, there are still 4 things you can do to
          upgrade your team:
        </p>
        <ul>
          <li>Team Spirit</li>
          <li>Tactical Training</li>
          <li>Familiarity</li>
          <li>Management Skills</li>
        </ul>
        <p>
          The first 3 can be improved by simply using the managers and players
          you want to integrate in your team. The number will develop as you
          play more and more games.
        </p>
        <p>
          If you're in a hurry, you can also apply specific Items to instantly
          boost those aspects, provided you have some in stock in your club.
        </p>
        <p>
          Management Skills can only be boosted with "Management Skills Boost"
          items. If you have a team filled with Superstars, you may need to use
          these.
        </p>
        <h3>Team Spirit</h3>
        <p>
          According to Konami, Team Spirit is "an overall indication of a group
          of factors, including a manager's suitability to your squad, each of
          your player's positional suitability and mastery of team instructions
          as well as your current Preset Tactics (both main and subs)".
        </p>
        <p>
          By simply playing more games, players will get more comfortable with
          their team mates and their positions. Your team will work better as a
          whole if your Team Spirit is 99.
        </p>
        <p>
          You should obviously use players in their preferred positions. Some of
          them are very versatile, and some may even have a higher rating in a
          non-default position. Messi is 94-rated as SS, but he’s actually 96 if
          you play him as AMF.
        </p>
        <p>
          You can access{" "}
          <a
            href="http://pesdb.net/pes2021/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PES DB
          </a>{" "}
          to quickly see a player’s preferred positions and his rating on each
          one.
        </p>
        <h3>Tactical Training</h3>
        <p>
          Tactical Training items can be applied to your team to boost the
          tactical prowess of players in relation to how they play under certain
          Team Instructions. This may increase Team Spirit as well, but since
          that grade relies on many factors, that's not guaranteed.
        </p>
        <p>
          You are awarded these items after certain time-limited events, such as
          completing a number of games/actions in the game.
        </p>
        <p>
          To use this item, visit the CLUB HOUSE / SQUAD MANAGEMENT screen,
          press the "Team Menu" button and use the "Tactical Training" item.
        </p>
        <p>
          Players that already have the highest rating for tactical prowess (99)
          will not be included in the training.
        </p>
        <h3>Familiarity Boost</h3>
        <p>
          If you’re bringing a new Manager to your squad and want to quickly
          increase their familiarity, you can use a Familiarity Boost. This will
          result in an increase in overall Team Spirit.
        </p>
        <p>
          This is something that you are awarded after certain time-limited
          events, such as completing a number of games/actions in the game.{" "}
        </p>
        <p>
          To use this item, you will need to go to the CLUB HOUSE / SQUAD
          MANAGEMENT screen, select your manager and click the "Manager Menu”
          button. There, you can apply the “Familiarity Boost” item.
        </p>
        <p>
          120% is the maximum Familiarity you can have for a manager. If that
          number has been reached, you won’t be able to use that item.
        </p>
        <h3>Management Skills Boost</h3>
        <p>
          Each player has individual costs related to their abilities, so a top
          player is more expensive. As the number of Black Balls in your team
          increase, so will the overall cost of your squad.
        </p>
        <p>
          Due to this, you will be forced to either sign a new Manager with
          higher Management Skills or increase the Management Skills of your
          current one with a Management Skills Boost.
        </p>
        <p>
          Each item will increase your Management Skills by 10 points, allowing
          you to add better players to your squad.{" "}
        </p>
        <p>
          Similar to the Familiarity Boost, this is something that you win
          randomly during time-limited events - still, you won’t get them as
          often.{" "}
        </p>
        <p>
          To use this item, you will need to go to the CLUB HOUSE / SQUAD
          MANAGEMENT screen, select your manager and click the "Manager Menu”
          button. There, you can apply the “Management Skills Boost” item.
        </p>
        <p>
          1.200 is the maximum Management Skills you can have for a Manager.
        </p>
      </div>
    </Layout>
  )
}

export default managerUpgrades
